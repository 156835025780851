import React from "react";
import invleft from "../../assets/inv2.jpeg";
import invright from "../../assets/inv3.png";
import midinv from "../../assets/midinv.jpeg";

const GetInvImages = () => {
  const imageStyle = {
    width: "100%",
    height: "auto",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", 
    borderRadius: "8px", // Optional: Add border radius for a rounded appearance
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-evenly", padding: "5px", marginTop: '.5rem' }}>
      <div style={{ flex: 1 }}>
        <img style={{ ...imageStyle, width: "90%" }} src={invleft} alt="" />
      </div>

      <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
        <img style={imageStyle} src={midinv} alt="" />
      </div>

      <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
        <img style={{ ...imageStyle, width: "90%" }} src={invright} alt="" />
      </div>
    </div>
  );
};

export default GetInvImages;
