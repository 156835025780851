import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import logo1 from "../../assets/icon2.png";
import missionIcon from "../../assets/missIcon.png";
import mission from "../../assets/missionIcon.png";
// import wokringTogether from '../../assets/workingtogetherIcon.png'
import "./works.css";
import { Button } from "@mui/material";

const Works = () => {
  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const workInfoData = [
    {
      image: mission,
      title: "Our Programs",
      text: "At People Like me, we are dedicated to making a postive impact through a variety of programs and initiatives.",
      link: "/programs",
    },
    {
      image: missionIcon,
      title: "Our Mission",
      text: "We aim to empower individuals and families to restore hope, achieve stability.",
      link: "/about-us",
    },
    {
      image: logo1,
      title: "Upcoming Events",
      text: "Join us for exciting upcoming events that bring our community together. ",
      link: "/event-info",
    },
  ];

  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <p style={{ fontSize: "32px", color: "#8983bf", marginTop: "10px" }}>
          {" "}
          <b>About</b>{" "}
        </p>
        <h3 className="about-heading">We're Here To Help</h3>
        <p className="about-text">
          People Like Me knows that our{" "}
          <span
            style={{
              fontFamily: "cursive",
              fontStyle: "italic",
              fontWeight: "bold",
              color: "#e6a168 ",
            }}
          >
            strength
          </span>{" "}
          lies not only in the words we stand by, but most importantly through
          the{" "}
          <span
            style={{
              fontFamily: "cursive",
              fontWeight: "bold",
              fontStyle: "italic",
              color: "#e6a168 ",
            }}
          >
            actions
          </span>{" "}
          of our initiatives.
        </p>
      </div>
      <div className="work-section-bottom">
        {workInfoData.map((data) => (
          <div className="work-section-info" key={data.title}>
            <div className="info-boxes-img-container">
              <img src={data.image} alt="" />
            </div>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
            {/* Use Link for navigation */}
            <Link
              onClick={handleScrollToTop}
              style={{ textDecoration: "none" }}
              to={data.link}
            >
              <Button
                variant="contained"
                style={{
                  borderColor: "#8983bf",
                  backgroundColor: "#8983bf",
                  marginTop: ".5rem",
                }}
              >
                Read more
              </Button>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Works;
