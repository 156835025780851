import React from "react";
import "./aboutus.css";
// import img1 from "../assets/about1.jpg";

import impact from "../assets/impact.png";
import teamimg from "../assets/bettermissionimg.png";
// import img2 from "../assets/about2.jpg";
// import img3 from "../assets/about3.jpg";
// import img4 from "../assets/about4.jpg";
// import img5 from "../assets/about1.jpg";
// import img6 from "../assets/about6.jpg";
import mission from "../assets/mission.png";
// import AboutCarousel from "../Components/Images/AboutCarousel";
import ContactModal from "../Components/Modals/contactModal";
import "react-slideshow-image/dist/styles.css";
import { Button } from "@mui/material";
import Footer from "../Components/Footer/Footer";
import { useNavigate } from "react-router-dom";
// import Sponsors from "../Components/Sponsor/Sponsors";
import { Diversity2, Diversity3, TrackChanges } from "@mui/icons-material";
import Getinvolved from "../Components/GetInvolved/GetInvolved";

const AboutUs = () => {
  const nav = useNavigate();

  const handleScrollToTop = () => {
    // Scroll to the top of the page
    // console.log("Scroll to Top clicked");
    window.scrollTo(0, 0);
  };

  const handleRedirect = () => {
    let url = "https://www.aplos.com/aws/give/PeopleLikeMeProject/PLMGiving ";

    window.open(url, "_blank");
  };

  const isMobile = window.innerWidth <= 768 && window.innerHeight <= 1024;

  return (
    <div>
      <div
        style={{
          backgroundColor: "#f2efef",
          boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            marginTop: "4.2rem",
            backgroundColor: "#f2efef",
            padding: ".5rem",
          }}
          className="work-section-top"
        >
          <p style={{ fontSize: "32px", color: "#8983bf", marginTop: "10px" }}>
            <b>Learn More</b>
          </p>
          <h1 className="programs-subheading">About Us</h1>
          <p className="primary-text">
            People Like Me is a community-based organization{" "}
            <span
              style={{
                fontFamily: "cursive",
                fontWeight: "bold",
                fontStyle: "italic",
                color: "#e6a168 ",
              }}
            >
              {" "}
              dedicated{" "}
            </span>{" "}
            to providing prevention services to youth, adults, and{" "}
            <span
              style={{
                fontFamily: "cursive",
                fontWeight: "bold",
                fontStyle: "italic",
                color: "#e6a168 ",
              }}
            >
              {" "}
              communities
            </span>.{" "}
            
          </p>
        </div>
        {/* <AboutCarousel /> */}
      </div>

      <div>
        <h3
          style={{
            fontSize: isMobile ? "1.4rem" : "2rem",
            marginLeft: "1.5rem",
            background: "linear-gradient(45deg, #7d74ab, #8983bf, #a395cc)",
            color: "#ececec",
          }}
          className="primary-text"
        >
          {" "}
          Success Is Your Birthright!
        </h3>
        <p className="aboutpage-ptext">
          As a prevention service organization serving youth, and adults within
          our communities we provide evidence-based education along with
          alcohol, tobacco, and other drug (ATOD) prevention services. Utilizing
          age appropriate strategies for the African American community,
          including re-entry and wraparound services.
        </p>
        <div>
          <hr
            style={{
              border: "1.5px solid #8983bf",
              width: "10%",
              margin: "0",
              marginLeft: "3rem",
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",

            flexDirection: isMobile ? "column" : "",
          }}
        >
          {/* Image */}
          <img
            src={mission} // Replace with the actual path to your image
            alt="mission"
            style={{
              maxWidth: isMobile ? "90%" : "40%", // Adjust width for mobile
              height: "auto",
              marginLeft: isMobile ? "0" : "2rem", // Adjust margin for mobile
              marginTop: isMobile ? "1rem" : "1.5rem", // Adjust margin for mobile
            }}
          />

          {/* Text, Icon, and Button */}
          <div
            style={{
              marginLeft: isMobile ? "0" : "4rem",
              marginTop: "1rem",
              // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              padding: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <TrackChanges style={{ color: "#8983bf", fontSize: 55 }} />

              <h2 style={{ marginLeft: "1rem", color: "#6a6a6a" }}>
                Our Mission
              </h2>
            </div>

            {/* Mission Description */}
            <p className="aboutpage-ptext">
              At the heart of our mission is the commitment to empower
              individuals and families, helping them restore hope, achieve
              stability, and thrive. We provide comprehensive support services,
              dedicated advocacy, and impactful education to create a lasting
              positive impact on the well-being of those we serve.
            </p>

            {/* Learn More Button */}
            <div style={{ textAlign: "center" }}>
              <Button
               onClick={() => {
                nav("/programs");
                handleScrollToTop();
               }}
                variant="contained"
                style={{
                  backgroundColor: "#8983bf",
                  borderColor: "grey",
                  marginTop: "1rem",
                }}
              >
                Learn More
              </Button>
            </div>
          </div>
        </div>

        {/* Start of second  */}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: isMobile ? "1rem" : "4rem",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          {/* Text, Icon, and Button */}
          <div
            style={{
              marginLeft: isMobile ? "0" : "4rem",
              marginTop: "1rem",
              // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              padding: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Diversity2 style={{ color: "#8983bf", fontSize: 55 }} />

              <h2 style={{ marginLeft: "1rem", color: "#6a6a6a" }}>
                Our Impact
              </h2>
            </div>

            {/* Impact Description */}
            <p className="aboutpage-ptext">
              Since 2011, we have been striving to make a real change in the
              lives of those who need it most. Our impact has been significant,
              reaching across communities and touching the lives of countless
              individuals. Together, we continue to write a narrative of impact,
              making strides toward a brighter and more promising future for
              all.
            </p>

            {/* Learn More Button */}
            <div style={{ textAlign: "center" }}>
              <Button
                onClick={() => {
                  handleScrollToTop();
                  nav("/impact");
                }}
                variant="contained"
                style={{
                  backgroundColor: "#8983bf",
                  borderColor: "grey",
                  marginTop: "1rem",
                }}
              >
                See our impact
              </Button>
            </div>
          </div>

          {/* Image */}
          {!isMobile && (
            <img
              src={impact}
              alt="impact"
              style={{
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                maxWidth: "40%",
                height: "auto",
                marginRight: "2rem",
                marginTop: "1rem",
              }}
            />
          )}
        </div>

        {/* Start of 3rd */}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: isMobile ? "column" : "",
          }}
        >
          {/* Image */}
          {!isMobile && (
            <img
              src={teamimg} // Replace with the actual path to your image
              alt="team"
              style={{
                maxWidth: isMobile ? "80%" : "40%", // Adjust width for mobile
                height: "auto",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                marginLeft: isMobile ? "0" : "2rem", // Adjust margin for mobile
                marginTop: isMobile ? "4rem" : "5rem", // Adjust margin for mobile
              }}
            />
          )}

          {/* Text, Icon, and Button */}
          <div
            style={{
              marginLeft: isMobile ? "0" : "4rem",
              marginTop: "4rem",
              // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              padding: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Diversity3 style={{ color: "#8983bf", fontSize: 55 }} />

              <h2 style={{ marginLeft: "1rem", color: "#6a6a6a" }}>Our Team</h2>
            </div>

            <p className="aboutpage-ptext">
              At People Like Me, our team is more than just a group of
              professionals — we are a dedicated family united by a shared
              passion for creating positive change. Our team brings together a
              rich tapestry of backgrounds, experiences, and expertise. We
              continuously strive to enhance our programs, staying abreast of
              the latest research and adapting our approaches to best serve the
              evolving needs of our community. Our team's commitment to
              excellence drives us to be leaders in the field of community
              empowerment and support services.
            </p>

            <div style={{ textAlign: "center" }}>
              <Button
                onClick={() => {
                  handleScrollToTop();
                  nav("/team");
                }}
                variant="contained"
                style={{
                  backgroundColor: "#8983bf",
                  borderColor: "grey",
                  marginTop: "1rem",
                }}
              >
                Meet the team
              </Button>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "6rem" }}>
          <Getinvolved />
        </div>
      </div>

      <div style={{ marginTop: "4rem" }}>
        <div style={{ marginTop: "1rem" }} className="center-container">
          <Button
            onClick={handleRedirect}
            variant="outlined"
            style={{
              color: "#8983bf",
              borderColor: "#8983bf",
              marginTop: "3rem",
            }}
          >
            MAKE A DONATION
          </Button>
          <ContactModal />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AboutUs;
