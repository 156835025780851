import React, { useState, useEffect } from "react";
import "./accomplishments.css";
import { Add } from "@mui/icons-material";

const Accomplishments = () => {
  const [countCP, setCountCP] = useState(0);
  const [emp, setEmp] = useState(0);
  const [meal, setMeal] = useState(0);
  const [youth, setYouth] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setEmp((prevCount) => (prevCount < 253 ? prevCount + 1 : prevCount));
      setCountCP((prevCount) => (prevCount < 1000 ? prevCount + 1 : prevCount));
      setMeal((prevCount) => (prevCount < 2160 ? prevCount + 1 : prevCount));
      setYouth((prevCount) => (prevCount < 97 ? prevCount + 1 : prevCount));
    }, 30);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="accomplishments-container">
      <div
        style={{ color: "#9c1d30", fontSize: "2rem", fontWeight: 'bolder' }}
        className="counter counter-youth"
      >
        {youth}{" "}
        <span style={{ color: "#9c1d30", fontSize: "2rem" }}>
          {" "}
          <Add />{" "}
        </span>
        <p style={{ color: "#9c1d30", fontWeight: 'normal'  }}>youth engaged in weekly programing</p>
      </div>
      <div
        style={{ color: "#94d3e5", fontSize: "2rem", fontWeight: 'bolder' }}
        className="counter counter-emp"
      >
        {emp}{" "}
        <span style={{ color: "#94d3e5", fontSize: "2rem" }}>
          <Add />
        </span>
        <p style={{ color: "#94d3e5", fontWeight: 'normal' }}>individuals secured jobs</p>
      </div>
      <div
        style={{ color: "#e6a168", fontSize: "2rem", fontWeight: 'bolder' }}
        className="counter counter-cp"
      >
        {countCP}{" "}
        <span style={{ fontSize: "2rem" }}>
          <Add />{" "}
        </span>
        <p style={{ color: "#e6a168", fontWeight: 'normal'  }}> care packages delivered</p>
      </div>
      <div
        style={{ color: "#a96737", fontSize: "2rem", fontWeight: 'bolder' }}
        className="counter counter-meal"
      >
        {meal}{" "}
        <span style={{ color: "#a96737", fontSize: "2rem",  }}>
          <Add />{" "}
        </span>
        <p style={{ color: "#a96737", fontWeight: 'normal'  }}>meals provided</p>
      </div>
    </div>
  );
};

export default Accomplishments;
