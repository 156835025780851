import React from "react";
import Chart from "react-apexcharts";

const Stats = () => {
  // Sample data for the bar chart
  const youthHelpedChartData = {
    options: {
      xaxis: {
        categories: [
          "2011",
          "2012",
          "2013",
          "2014",
          "2015",
          "2016",
          "2017",
          "2018",
          "2019",
          "2020",
          "2021",
          "2022",
          "2023",
        ],
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value / 1000 + "k";
          },
        },
      },
      colors: ["#8983bf"],
    },
    series: [
      {
        name: "Number of Youth Helped",
        data: [1043, 1531, 2082, 2190, 2416, 2920, 3120, 3413, 3660, 4180, 4228, 5044, 9828],
      },
    ],
  };

  const mealSupportChartData = {
    options: {
      xaxis: {
        categories: [
          "2011",
          "2012",
          "2013",
          "2014",
          "2015",
          "2016",
          "2017",
          "2018",
          "2019",
          "2020",
          "2021",
          "2022",
          "2023",
        ],
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value / 1000 + "k";
          },
        },
      },
      colors: ["#8983bf"],
    },
    series: [
      {
        name: "Number of Meals",
        data: [612, 780, 982, 1290, 1516, 1920, 2221, 2813, 2960, 3180, 3228, 3444, 3528],
      },
    ],
  };

  const jobsChartData = {
    options: {
      xaxis: {
        categories: [
          "2011",
          "2012",
          "2013",
          "2014",
          "2015",
          "2016",
          "2017",
          "2018",
          "2019",
          "2020",
          "2021",
          "2022",
          "2023",
        ],
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value / 1000 + "k";
          },
        },
      },
      colors: ["#8983bf"],
    },
    series: [
      {
        name: "Permanent Employment",
        data: [27, 42, 50, 60, 82, 103, 122, 140, 160, 180, 200, 220, 250],
      },
    ],
  };
  const expensesChartData = {
    options: {
      labels: ["Programs", "Administration", "Fundraising", "Other"],
      colors: ["#4caf50", "#2196f3", "#ff9800", "#e91e63"],
    },
    series: [50, 20, 15, 15], // Adjust these values based on your actual expense distribution
  };

  return (
    <div>
      {/* Bar Chart */}
      <div>
        <h2 style={{ marginLeft: '1rem' }} className="primary-text">Youth Served</h2>
        <Chart
          options={youthHelpedChartData.options}
          series={youthHelpedChartData.series}
          type="bar"
          height="350"
        />
      </div>
      <div>
        <h2 style={{ marginLeft: '1rem' }} className="primary-text">Meals Supported</h2>
        <Chart
          options={mealSupportChartData.options}
          series={mealSupportChartData.series}
          type="bar"
          height="350"
        />
      </div>
      <div>
        <h2 style={{ marginLeft: '1rem' }} className="primary-text">Jobs Secured</h2>
        <Chart
          options={jobsChartData.options}
          series={jobsChartData.series}
          type="bar"
          height="350"
        />
      </div>
        {/* Pie Chart */}
        <div>
        <h3 style={{fontSize: '1.6rem', marginLeft: '1.5rem', background: 'linear-gradient(45deg, #7d74ab, #8983bf, #a395cc)', color: '#ececec'}} className="primary-text"> Where Your Dollar Goes</h3>
        <p style={{marginLeft: '1rem'}} className="aboutpage-ptext">
          We emphasize transparency in everything we do, setting bold goals and
          pursuing a clear strategy that seeks to move the needle.
        </p>
        <Chart
          options={expensesChartData.options}
          series={expensesChartData.series}
          type="donut"
          height="350"
        />
      </div>

      {/* Call to Action */}
      <div>
      <h3 style={{fontSize: '1.6rem', marginLeft: '1.5rem', background: 'linear-gradient(45deg, #7d74ab, #8983bf, #a395cc)', color: '#ececec'}} className="primary-text"> Support The Cause</h3>
        <p style={{marginLeft: '1rem'}} className="aboutpage-ptext">
          Your contribution can make a difference! Join us in creating a positive impact on the community.
        </p>
      </div>
    </div>
  );
};

export default Stats;
