import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { Alert, TextField, Typography } from "@mui/material";
import emailjs from "@emailjs/browser";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

function ChildModal() {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    message: "",
  });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [isEmailSent, setIsEmailSent] = React.useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceId = "service_lbkdgkl";
    const templateId = "template_0xum3k7";
    const userKey = "tygsa-d8aLZv-qwVw";

    emailjs.init(userKey);

    emailjs.send(serviceId, templateId, formData).then(
      (response) => {
        // console.log("Email sent successfully:", response);
        setIsEmailSent(true);
        if (isEmailSent) {
          setFormData({
            name: "",
            email: "",
            message: "",
          });
          setTimeout(() => {
            setIsEmailSent(false);
          }, 3000);
        }
      },
      (error) => {
        console.error("Email sending failed:", error);
      }
    );
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        style={{ backgroundColor: "#8983bf", marginTop: "1rem" }}
        onClick={handleOpen}
      >
        Email us
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "white",
            boxShadow: 24,
            p: 2,
            textAlign: "center",
          }}
        >
          <Typography variant="h5" id="child-modal-title" gutterBottom>
            Reach out
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Name"
              name="from_name"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              onChange={handleInputChange}
            />
            <TextField
              label="Email"
              name="user_email"
              variant="outlined"
              fullWidth
              margin="normal"
              type="email"
              required
              onChange={handleInputChange}
            />
            <TextField
              label="Message"
              name="message"
              variant="outlined"
              multiline
              rows={4}
              fullWidth
              margin="normal"
              required
              onChange={handleInputChange}
            />
            <Button
              type="submit"
              variant="contained"
              style={{ backgroundColor: "#8983bf" }}
              sx={{ marginTop: 2 }}
            >
              Submit
            </Button>
            {isEmailSent && (
              <Alert severity="success">Email sent successfully!</Alert>
            )}
          </form>
          <Button
            variant="outlined"
            style={{ borderColor: "#8983bf", color: "#8983bf" }}
            onClick={handleClose}
            sx={{ marginTop: 1 }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default function NestedModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        onClick={handleOpen}
        variant="contained"
        style={{
          borderColor: "#8983bf",
          backgroundColor: "#8983bf",
          margin: ".5rem",
        }}
      >
        Questions?
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 400 }}>
          <h2 id="parent-modal-title">Have a question?</h2>
          <p id="parent-modal-description">
            If you have a question about who we are, what we do, or how you can
            help our community we want to hear from you.
          </p>
          <p style={{ marginTop: ".5rem" }}>
            <a style={{ color: "black" }} href="tel:+1234567890">
              Call Us
            </a>{" "}
          </p>
          <ChildModal />
        </Box>
      </Modal>
    </div>
  );
}
