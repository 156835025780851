import React from "react";
import CustomizedAccordions from "./CustomizedAccordions";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
// import Stats from "./Stats";
// import HomeStats from "./HomeStats";

const Resources = () => {
  const nav = useNavigate();

  const handleScrollToTop = () => {
    // Scroll to the top of the page
    nav("/impact");
    window.scrollTo(0, 0);
  };

  const navToPro = () => {
    nav("programs");
    window.scrollTo(0, 0);
  };

  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <p style={{ fontSize: "32px", color: "#8983bf", marginTop: "10px" }}>
          <b>Explore</b>
        </p>
        <h1 className="sub-heading">Our Resources</h1>
        <p className="primary-text">
          We have a wide range of resources designed to{" "}
          <span
            style={{
              fontFamily: "cursive",
              fontStyle: "italic",
              fontWeight: "bold",
              color: "#e6a168 ",
            }}
          >
            enhance
          </span>{" "}
          and{" "}
          <span
            style={{
              fontFamily: "cursive",
              fontStyle: "italic",
              fontWeight: "bold",
              color: "#e6a168 ",
            }}
          >
            fortify{" "}
          </span>{" "}
          our community.
        </p>

        <div>
          <CustomizedAccordions />
        </div>
        <div
          style={{ textAlign: "center", marginTop: "1rem", padding: "1rem" }}
        >
          <Button onClick={navToPro} style={{ color: "#8983bf" }}>
            View more
          </Button>
          <Button onClick={navToPro} style={{ color: "#e6a168" }}>
            Need assistance?
          </Button>
          <Button onClick={handleScrollToTop} style={{ color: "#8983bf" }}>
            See our impact
          </Button>
        </div>
        {/* <HomeStats /> */}
      </div>
      <div
        style={{ textAlign: "center", marginTop: "1rem", padding: "1rem" }}
      ></div>
    </div>
  );
};

export default Resources;
