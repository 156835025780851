import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Components/Home/Home";
import NavBar from "./Components/NavBar";
import AboutUs from "./Pages/AboutUs";
import ProgramsInfo from "./Pages/ProgramsInfo";
import ContactUs from "./Pages/ContactUs";
import EventsInfo from "./Pages/EventsInfo";
import Team from "./Pages/Team";
// import Donate from "./Pages/Donate";
import Impact from "./Pages/Impact";
// import { useEffect, useState } from "react";
// import Popup from "./Components/PopUps/Popup";
import Donate from "./Pages/Donate";
import HonorsEvents from "./Pages/HonorsEvents";
import { useEffect, useState } from "react";
import Popup from "./Components/PopUps/Popup";

function App() {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {

    const timeoutId = setTimeout(() => {
      setShowPopup(true);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="App">
      <NavBar />

      {showPopup && <Popup onClose={closePopup} />}
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/about-us/contact-us" element={<ContactUs />} />
        <Route path="about-us/action-steps" element={<Donate />} />
        <Route path="/about-us/programs" element={<ProgramsInfo />} />

        <Route path="/programs" element={<ProgramsInfo />} />
        <Route path="counseling/programs" element={<ProgramsInfo />} />
        <Route path="programs/action-steps" element={<Donate />} />

        <Route path="/contact-us" element={<ContactUs />} />

        <Route path="/impact" element={<Impact />} />

        <Route path="action-steps" element={<Donate />} />

        <Route path="/event-info" element={<EventsInfo />} />

        <Route path="/programs/contact-us" element={<ContactUs />} />

        <Route path="/honors-events" element={<HonorsEvents />} />
        <Route path="/honors-events/programs" element={<ProgramsInfo />} />
        <Route path="/honors-events/events-info" element={<EventsInfo />} />

        <Route path="/team" element={<Team />} />
      </Routes>
    </div>
  );
}

export default App;
