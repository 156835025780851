import React from "react";
import ajay from "../../assets/ajayHeadshot.jpg";
import "./founder.css";
import { Button } from "@mui/material";
import { Newspaper } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const MeetTheFounder = () => {
  const nav = useNavigate();
  const handleScrollToTop = () => {
    nav("/team");
    window.scrollTo(0, 0);
  };

  const scrollToNews = () => {
    nav('event-info');
    window.scrollTo(-1,2)
     
  }

  return (
    <div className="founder-section">
      <h1
        style={{ color: "#8983bf", textAlign: "center", paddingBlock: "1rem" }}
      >
        A Jay Pullen
      </h1>
      <h3 className="sub-heading" style={{ textAlign: "center" }}>
        Founder & CEO
      </h3>
      <div className="founder-container">
        <div className="founder-info">
          <div className="image-container">
            <img src={ajay} alt="Ajay Pullen" />
          </div>
        </div>
        <div className="ajay-bio">
          <p>
            A Jay Pullen takes pride in his purpose as an advocate for change in
            the lives of youth and adults in our communities in need. After
            accepting his call to ministry in 2007, he became the youth pastor
            at Victory Church of God in Columbus Ohio. He is steadfast in his
            commitment to improving the lives of youth and families in our
            communities. In 2011, A Jay put to life his vision by honoring local
            influential African-American change agents who are making a
            difference in the community. A Jay is the former CEO of the Central
            Ohio Youth & Adult Program (COYAP). Currently, A Jay is the
            President and CEO of People Like Me Project. Recently, has been
            selected to serve on the Ohio Community Recovery Support Planning
            Council and the Ohio Statewide Family Engagement Advisory Board. He
            also served as the co-chair of the Westerville City Schools Drug
            Awareness Committee and had served as Mentor for the Alvis Re-Entry
            Program.
          </p>
          <Button
            onClick={handleScrollToTop}
            variant="outlined"
            style={{
              color: "#8983bf",
              borderColor: "#8983bf",
              display: "flex",
              margin: "auto",
              marginTop: "1rem",
            }}
          >
            Meet the team
          </Button>
          <Button
          onClick={scrollToNews}
            style={{
              color: "#949494",
              borderColor: "#949494",
              display: "flex",
              margin: "auto",
              marginTop: "1rem",
            }}
          >
            {" "}
            <Newspaper /> {" "} news & Events
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MeetTheFounder;
