import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Slide,
  styled,
  Divider,
} from "@mui/material";

// Define custom styles using styled
const StyledDialog = styled(Dialog)({
  borderRadius: 8,
  boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.2)",
});

const StyledButton = styled(Button)({
  color: "#fff",
  backgroundColor: "#8983bf",
  "&:hover": {
    backgroundColor: "#9993d7",
  },
});

const StyledDialogTitle = styled(DialogTitle)({
  backgroundColor: "#8983bf",
  color: "#fff",
  padding: "16px",
  textAlign: "center",
});

const StyledDialogContent = styled(DialogContent)({
  padding: "16px",
});

const StyledDialogActions = styled(DialogActions)({
  padding: "16px",
});

const StyledListItem = styled(ListItem)({
  borderBottom: "1px solid #ddd",
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Engagement = ({
  isModalOpen,
  openEngagementModal,
  closeEngagementModal,
}) => {
  return (
    <div>
      <div style={{ marginTop: "2rem", textAlign: "center" }}>
        <StyledButton variant="contained" onClick={openEngagementModal}>
          More Info
        </StyledButton>
      </div>

      {/* Modal */}
      <StyledDialog
        open={isModalOpen}
        onClose={closeEngagementModal}
        TransitionComponent={Transition}
      >
        <StyledDialogTitle> Coummity Engagement </StyledDialogTitle>
        <Divider></Divider>
        <StyledDialogTitle>Services</StyledDialogTitle>
        <StyledDialogContent>
          <List>
            <StyledListItem>
              <ListItemText primary="Vote Registration & Education" />
            </StyledListItem>
            <StyledListItem>
              <ListItemText
                primary="Community Conversations
"
              />
            </StyledListItem>
            <StyledListItem>
              <ListItemText primary="Community Giveaways" />
            </StyledListItem>
            <StyledListItem>
              <ListItemText primary="Youth Summits" />
            </StyledListItem>
            <StyledListItem>
              <ListItemText
                primary="Food and Clothing Drives
 "
              />
            </StyledListItem>
            <StyledListItem>
              <ListItemText
                primary="Volunteer Opportunities


 "
              />
            </StyledListItem>
          </List>
        </StyledDialogContent>
        <StyledDialogActions>
        <p>For questions and more information call or email us.</p>
          <StyledButton onClick={closeEngagementModal}>Close</StyledButton>
        </StyledDialogActions>
      </StyledDialog>
    </div>
  );
};

export default Engagement;
