import React from "react";
import "./donate.css";
import Chart from "react-apexcharts";
import Footer from "../Components/Footer/Footer";
import {
  FaDonate,
  FaFacebook,
  FaHandsHelping,
  FaInstagram,
  FaLinkedin,
  FaPeopleArrows,
  FaTwitter,
} from "react-icons/fa";

import GetInvImages from "../Components/Images/GetInvImages";

import HowToVolunteerStepper from "../Components/Steppers/HowToVolunteerStepper";
import { Button } from "@mui/material";
import { IconContext } from "react-icons";
// import { West } from "@mui/icons-material";
// import { NavLink } from "react-router-dom";

const Donate = () => {
  const handleRedirect = () => {
    let url = "https://www.aplos.com/aws/give/PeopleLikeMeProject/PLMGiving ";

    window.open(url, "_blank");
  };

  const expensesChartData = {
    options: {
      labels: ["Programs", "Administration", "Fundraising", "Other"],
      colors: ["#4caf50", "#2196f3", "#ff9800", "#e91e63"],
    },
    series: [50, 20, 15, 15], // Adjust these values based on your actual expense distribution
  };

  return (
    <div>
      <div
        style={{
          marginTop: "4.2rem",
          backgroundColor: "#f2efef",
          padding: ".5rem",
          boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.1)",
        }}
        className="work-section-top"
      >
        <p style={{ fontSize: "32px", color: "#8983bf", marginTop: "10px" }}>
          <b>Take Action</b>
        </p>
        <h1 className="programs-subheading">Get Involved</h1>
        <p className="primary-text">
          People Like Me recognizes that to create a lasting impact, we rely on
          your support. By contributing through donations and volunteering, we
          can unite to make a tangible difference in the lives of those who need
          it most.
        </p>
      </div>
      {/* <NavLink to='/' style={{marginLeft: '1rem', color: 'black'}} >
        <West /> 
      </NavLink> */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          margin: "1rem",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <FaHandsHelping style={{ fontSize: 70, color: "#8983bf" }} />
          <p className="primary-text">Volunteer</p>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <FaDonate style={{ fontSize: 70, color: "#8983bf" }} />
          <p className="primary-text">Donate</p>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <FaPeopleArrows style={{ fontSize: 70, color: "#8983bf" }} />
          <p className="primary-text">Share</p>
        </div>
      </div>

      <div>
        <h3
          style={{ marginLeft: "1rem", fontSize: "2rem" }}
          className="primary-text"
        >
          How to Volunteer
        </h3>
        <hr
          style={{
            border: "1.5px solid #8983bf",
            width: "10%",
            margin: "0",
            marginLeft: "1rem",
          }}
        />
        <HowToVolunteerStepper />
      </div>

      <div style={{ backgroundColor: "#f0f0f0" }}>
        <h3
          style={{ marginLeft: "1rem", fontSize: "2rem" }}
          className="primary-text"
        >
          How to Donate
        </h3>
        <hr
          style={{
            border: "1.5px solid #8983bf",
            width: "10%",
            margin: "0",
            marginLeft: "1rem",
          }}
        />
        <div style={{ backgroundColor: "#f0f0f0" }}>
          {/* Content below the hr with a different background color */}
          <p style={{ marginLeft: "1rem" }} className="primary-text">
            Simply click the button to get started.
          </p>
          <div style={{ textAlign: "center" }}>
            <Button
              onClick={handleRedirect}
              variant="contained"
              style={{ background: "#8983bf" }}
            >
              MAKE A DONATION
            </Button>
          </div>

          <p
            style={{ marginTop: "1rem", padding: "1rem", textAlign: "center" }}
            className="pri"
          >
            We emphasize transparency in everything we do, and want you to know
            where your dollar goes. We set bold goals, and pursue a clear
            strategy that seeks to move the needle.
          </p>
          <div style={{ justifyContent: "center", alignItems: "center" }}>
            <div>
              <Chart
                options={expensesChartData.options}
                series={expensesChartData.series}
                type="donut"
                height="450"
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <h3
          style={{ marginLeft: "1rem", fontSize: "2rem" }}
          className="primary-text"
        >
          Spread The Word
        </h3>
        <hr
          style={{
            border: "1.5px solid #8983bf",
            width: "10%",
            margin: "0",
            marginLeft: "1rem",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "", // Change to column direction
            alignItems: "center", // Center items horizontally
            marginTop: "1rem",
          }}
        >
          <div>
            <p
              style={{ marginLeft: "1rem", marginTop: "1rem" }}
              className="primary-text"
            >
              Utilize the power of social media to make a positive impact! Share
              our mission with your friends and followers. Together, we can
              create a ripple effect that reaches far and wide.
            </p>
          </div>

          <IconContext.Provider
            value={{
              size: "2em",
              color: "#8983bf",
              style: { margin: "0.5rem" },
            }}
          >
            {/* Social media icons with links */}
            <a
              href="https://www.facebook.com/PLMPROJECT"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook />
            </a>
            <a
              href="https://twitter.com/plmpinc"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter />
            </a>
            <a
              href="https://instagram.com/plmpinc/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </a>
            <a
              href="https://www.linkedin.com/company/peoplelikeme/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin />
            </a>
            {/* Add more social media icons as needed */}
          </IconContext.Provider>
        </div>
        <GetInvImages />
      </div>

      <Footer />
    </div>
  );
};

export default Donate;
