import React from "react";
import ContactHomeCustome from "../Components/Contact/ContactHomeCustome";
import Footer from "../Components/Footer/Footer";
const ContactUs = () => {
  return (
    <div>
      <h1
        style={{
          color: "#8983bf",
          textAlign: "center",
          paddingBlock: "1rem",
          marginTop: "6rem",
        }}
      >
        How can we help?
      </h1>
      <div>
        <ContactHomeCustome />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default ContactUs;
