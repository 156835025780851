import React from "react";
import honorsevents from "../assets/hevents.jpg";
import hppl from "../assets/hppl.jpg";
import { Button, Divider } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { West } from "@mui/icons-material";
import Footer from "../Components/Footer/Footer";
const HonorsEvents = () => {
  const nav = useNavigate();

  // const navToPro = () => {
  //   nav("programs");
  //   window.scrollTo(0, 0);
  // };

  const navToEvents = () => {
    nav("events-info");
    window.scrollTo(0, 0);
  };

  return (
    <div style={{ marginTop: "6rem" }}>
      <div style={{ marginLeft: "1rem" }}>
        <NavLink style={{ color: "black" }} to="/">
          <West />
        </NavLink>
      </div>
      <h3
        style={{
          fontSize: "2rem",
          marginLeft: "1.5rem",
          background: "linear-gradient(45deg, #7d74ab, #8983bf, #a395cc)",
          color: "#ececec",
        }}
        className="primary-text"
      >
        {" "}
        Honors Events
      </h3>
      <p style={{ marginLeft: "2rem" }} className="primary-text">
        Our Honor Events recognize the achievements of African Americans
        contributing to the positive development of communities and connects
        youth from The Outlet program to successful and prominent African
        Americans.
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          flexWrap: "wrap",
        }}
      >
        <img
          style={{ width: "330px", marginBottom: "20px" }}
          src={honorsevents}
          alt=""
        />
        <img
          style={{ width: "360px", marginBottom: "20px" }}
          src={hppl}
          alt=""
        />
      </div>
      <div style={{ marginTop: "5rem" }}>
        <Divider>HOW WE'VE GROWN</Divider>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2rem",
        }}
      >
        <div
          style={{
            height: "20px",
            width: "60%",
            backgroundColor: "#8983bf",
            marginBottom: "1rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h3 style={{ fontSize: "1rem", color: "#ffffff" }}>
            Est. 2011 (50 participants)
          </h3>
        </div>
        <div
          style={{
            height: "30px",
            width: "70%",
            backgroundColor: "#8983bf",
            marginBottom: "1rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h3 style={{ fontSize: "1rem", color: "#ffffff" }}>Annual Events</h3>
        </div>
        <div
          style={{
            height: "40px",
            width: "80%",
            backgroundColor: "#8983bf",
            marginBottom: "1rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h3 style={{ fontSize: "1rem", color: "#ffffff" }}>
            Increased 300+ participants
          </h3>
        </div>
        <div
          style={{
            height: "50px",
            width: "95%",
            backgroundColor: "#8983bf",
            marginBottom: "1rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h3
            style={{ fontSize: "1rem", color: "#ffffff", textAlign: "center" }}
          >
            Proceeds benefit youth with school supplies, uniforms & resources
          </h3>
        </div>
      </div>
      <div style={{ textAlign: "center", marginTop: "1rem" }}>
        <Button
          variant="outlined"
          style={{ borderColor: "#8983bf", color: "#8983bf" }}
          onClick={navToEvents}
        >
          See upcoming events
        </Button>

        {/* <Button
          variant="contained"
          style={{ background: "#8983bf", marginLeft: '8px' }}
          onClick={navToEvents}
        >
          Upcoming honors event
        </Button> */}
      </div>
      <Footer />
    </div>
  );
};

export default HonorsEvents;
