// EventsInfo.js

import React from "react";
import EventCard from "../Components/Events/EventCard";

import "./events.css";

const EventsInfo = () => {
  return (
    <div className="events-info-container">
      <h2 className="events-heading" style={{ marginTop: "5rem" }}>
        Our Events
      </h2>
      <p>Be sure to check back as we will be updating events weekly!</p>
      <div className="upcoming-events">
        <h3
          style={{
            fontSize: "2rem",
            marginLeft: "1.5rem",
            background: "linear-gradient(45deg, #7d74ab, #8983bf, #a395cc)",
            color: "#ececec",
          }}
          className="primary-text"
        >
          {" "}
          Upcoming Events
        </h3>
        {/* <hr
          style={{
            border: "1.5px solid #8983bf",
            width: "10%",
            margin: "0",
            marginLeft: "1rem",
          }}
        /> */}
        <EventCard />
      </div>
      <div className="upcoming-events">
        <h3
          style={{
            fontSize: "2rem",
            marginLeft: "1.5rem",
            background: "linear-gradient(45deg, #7d74ab, #8983bf, #a395cc)",
            color: "#ececec",
          }}
          className="primary-text"
        >
          {" "}
          Recent News
        </h3>
        <hr
          style={{
            border: "1.5px solid #8983bf",
            width: "10%",
            margin: "0",
            marginLeft: "1rem",
          }}
        />
        {/* <EventCard /> */}
        <h3 className="primary-text">
          Read recent articles featuring People Like Me & Founder A Jay Pullen
        </h3>
        <p className="primary-text">
          By ABC6 <span className="arrow-right">→</span>{" "}
          <a
            href="https://abc6onyourside.com/news/local/community-outreach-groups-inspire-kids-organization-people-like-me-project-advocates-columbus-neighborhoods"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read Article
          </a>
        </p>
        <p className="primary-text">
          By ABC6 <span className="arrow-right">→</span>{" "}
          <a
            href="https://abc6onyourside.com/news/local/trouble-arises-at-night-columbus-community-mayor-react-to-2-teens-shot-overnight-reduce-gun-violence-youth-crime-stoppers-curfew-people-like-me-project-central-ohio#"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read Article
          </a>
        </p>
      </div>

      {/* <Footer /> */}
    </div>
  );
};

export default EventsInfo;
