import React, { useEffect, useState } from "react";
import { Paper, Typography, CardContent, CardActions } from "@mui/material";
import Confetti from "react-confetti";
import { AddToCalendarButton } from "add-to-calendar-button-react";

const dummyEventData = [
  {
    id: 1,
    title: "Children's Coat & Shoe Give Away",
    description:
      "Helping Families in Need. Please note: first come, first serve.",
    date: new Date("2024-01-13T18:00:00"),
    time: "11am-1pm",
    location: 'PLM Program Site @ 1500 Barnett Rd Columbus, Ohio 43227'
  },
  {
    id: 2,
    title: "Central Ohio Honors Event 2024",
    description:
      "Honoring The Legacy",
      date: new Date("2024-04-19T18:00:00"),
    time: "6pm",
    location: 'Sheraton Columbus Hotel at Capitol Square, 75 E State St, Columbus, OH 43215'
  },
  {
    id: 3,
    title: "Children's Coat & Shoe Give Away",
    description:
      "Helping Families in Need. Please note: first come, first serve.",
      date: new Date("2024-01-13T18:00:00"),
    time: "11am-1pm",
    location: 'PLM Program Site @ 1500 Barnett Rd Columbus, Ohio 43227'
  },
];

const buttonStyle = {
  fontSize: "12px",
  padding: "2px 3px",
};

const EventCard = ({ event }) => {
  const formatDate = (dateString) => {
    if (!dateString) {
      return "N/A";
    }

    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      // Handle the case where date is not a valid date
      return "N/A";
    }

    const formattedDate = date.toISOString().split("T")[0];
    return formattedDate;
  };

  return (
    <Paper
      elevation={3}
      style={{
        padding: "1rem",
        maxWidth: "300px",
        margin: "1rem",
        backgroundColor: "#f2efef",
      }}
      key={event.id}
    >
      <Typography variant="h6" gutterBottom>
        {event.title}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {event.description}
      </Typography>
      <CardContent style={{ marginTop: "8px" }}>
        <Typography variant="body2" color="text.secondary">
          Date: {formatDate(event.date)}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Time: {event.time}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Location:  {event.location}
        </Typography>
      </CardContent>
      <CardActions>
        <div style={buttonStyle}>
          <AddToCalendarButton
            name={event.title}
            startDate={formatDate(event.date)}
            options={["Apple", "Google", "Yahoo"]}
            timeZone="currentBrowser"
          />
        </div>
      </CardActions>
    </Paper>
  );
};

const EventList = () => {

  const [isCelebrationVisible, setCelebrationVisibility] = useState(true);

  useEffect(() => {
    // Set a timer to hide the celebration after 5 seconds
    const timer = setTimeout(() => {
      setCelebrationVisibility(false);
    }, 5000);

    // Clear the timer on component unmount to avoid memory leaks
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
    {isCelebrationVisible && <Confetti />} 
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
      }}
    >
      {dummyEventData.map((event) => (
        <EventCard key={event.id} event={event} />
      ))}
    </div>
  </div>
  );
};

export default EventList;
