import React from "react";
import Stats from "../Components/Resources/Stats";
import Footer from "../Components/Footer/Footer";
import { Button } from "@mui/material";
import ContactModal from "../Components/Modals/contactModal";

const Impact = () => {
  return (
    <div>
      <div
        style={{
          marginTop: "4.2rem",
          backgroundColor: "#f2efef",
          padding: ".5rem",
          boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.1)",
        }}
        className="work-section-top"
      >
        <p style={{ fontSize: "32px", color: "#8983bf", marginTop: "10px" }}>
          <b>Spreading Love</b>
        </p>
        <h1 className="programs-subheading">Building Community</h1>
        <p className="primary-text">
          Together, we can make a positive impact on our community. Whether it's
          supporting one another, fostering inclusivity, or contributing to
          meaningful projects, your involvement is the key to a stronger and
          more connected community.
        </p>
      </div>
      <div>
        <h3
          style={{
            fontSize: "2rem",
            marginLeft: "1.5rem",
            background: "linear-gradient(45deg, #7d74ab, #8983bf, #a395cc)",
            color: "#ececec",
          }}
          className="primary-text"
        >
          {" "}
          Our Impact
        </h3>
      </div>

      <Stats />

      <div style={{ marginTop: "1rem" }} className="center-container">
        <Button
          // onClick={handleRedirect}
          variant="outlined"
          style={{
            color: "#8983bf",
            borderColor: "#8983bf",
            marginTop: "3rem",
          }}
        >
          MAKE A DONATION
        </Button>
        <ContactModal />
      </div>

      <Footer />
    </div>
  );
};

export default Impact;
