import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div style={{ marginTop: "1rem", padding: "2rem" }}>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Behavioral Health</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Behavioral health includes the emotions and behaviors that affect
            your overall well-being. Everyone has the strength to make important
            behavioral health changes, but many people are too nervous to talk
            about it during a health care visit. Asking for support comes with
            many benefits though. As we gather around those in need, behavioral
            health services we offer are there to support you or your loved one
            throughout the journey.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Mentoring</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Our mentoring is a dynamic and supportive relationship in which an
            individual with more experience, knowledge, or wisdom (the mentor)
            guides, advises, and shares life skills with another person (the
            mentee) to foster personal and professional development. Through
            this relationship, mentoring becomes a transformative process that
            not only imparts practical skills but also cultivates resilience,
            confidence, and a sense of accomplishment in the face of shared
            adversities.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Re-entry</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Designed to assist individuals who have been incarcerated in
            reintegrating into society successfully. We aim to address the
            challenges and barriers that individuals may face during this
            transition.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Community Engagement</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Introducing a dynamic and inclusive platform designed to empower and
            uplift communities by fostering the development and accessibility of
            resources identified and prioritized by the community members
            themselves. Our mission is to enhance and, where needed,
            revolutionize the quality of individual lives through a
            collaborative approach that puts the power of positive change in the
            hands of those who know their community best.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel7"}
        onChange={handleChange("panel7")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Food & Clothing Drives</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Our Food & Clothing Drives are not just about providing physical
            resources; they embody the spirit of empathy and understanding.
            Volunteers play a pivotal role in sorting, organizing, and
            distributing the donations, creating opportunities for meaningful
            connections and shared experiences that transcend socio-economic
            barriers.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel8"}
        onChange={handleChange("panel8")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Workforce Development</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Tailored to address the diverse needs of participants, recognizing
            that each individual brings unique strengths and ambitions to the
            table. Through a combination of targeted training, mentorship, and
            career guidance, we strive to cultivate a workforce that is not only
            skilled and adaptable but also inspired to pursue meaningful and
            fulfilling careers.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
