// Popup.js

import React from "react";
import "./popup.css";
import { Close, EventAvailable } from "@mui/icons-material";
import latestEvent from "../../assets/latestevent.jpeg";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Popup = ({ onClose }) => {
  const nav = useNavigate();

  const handleAddToCalendar = () => {
    nav("/event-info");

    window.scrollTo(0, 0);

    onClose();
  };

  return (
    <div className="popup">
      <Close className="closeButton" onClick={onClose} />
      <img src={latestEvent} alt="Latest Event" />
      <p className="primary-text">Our upcoming event, come join us!</p>
      <div>
        <Button style={{ color: "#8983bf" }} onClick={handleAddToCalendar}>
          {" "}
          <EventAvailable /> Add to calander
        </Button>
      </div>
    </div>
  );
};

export default Popup;
