import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { NavLink } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function KrogerInfo() {
  const [open, setOpen] = React.useState(false);
  const [openKroger, setOpenKroger] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenKroger = () => setOpenKroger(true);

  const handleCloseKroger = () => setOpenKroger(false);

  return (
    <div>
      <NavLink
        style={{ marginLeft: "1rem", color: "black" }}
        onClick={handleOpenKroger}
      >
        Shop at kroger?
      </NavLink>
      <Modal
        open={openKroger}
        onClose={handleCloseKroger}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            You can support People Like Me just by shopping at Kroger!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            It’s easy when you enroll in Kroger Community Rewards®! To get
            started, sign up with your Plus Card. Be sure to have your Kroger
            Plus card handy and register your card with your organization after
            you sign up. Enter NPO number DF129 or A Tribute to African
            Americans, select an organization from the list, and click on
            confirm. Verify that you are enrolled correctly, you will see A
            Tribute to African Americans on the right side of your information
            page. Learn more <a href="https://www.kroger.com/i/community/community-rewards"><b>Here</b> </a>
          </Typography>
        </Box>
      </Modal>
      <NavLink
        style={{ marginLeft: "1rem", color: "black" }}
        onClick={handleOpen}
      >
        Shop at Amazon?
      </NavLink>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            When you shop on AmazonSmile, Amazon donates to us!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            AmazonSmile is a simple and automatic way for you to support your
            favorite charitable organization every time you shop, at no cost to
            you. When you shop at smile.amazon.com, you’ll find the exact same
            low prices, vast selection and convenient shopping experience as
            Amazon.com, with the added bonus that Amazon will donate a portion
            of the purchase price to your favorite charitable organization.
            Simply go to <a href="https://www.amazon.com/?ie=UTF8&ein=81-5437706&ref_=smi_ext_ch_81-5437706_cl"> <b>Here</b> </a>  to get started.
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
