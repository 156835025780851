import React, { useState } from "react";
import "./programs.css";
// import Getinvolved from "../Components/GetInvolved/GetInvolved";
import Footer from "../Components/Footer/Footer";
import { Button } from "@mui/material";
import ProgramCharts from "../Components/Programs/ProgramCharts";
import { Diversity1 } from "@mui/icons-material";
import ContactModal from "../Components/Modals/contactModal";
import Counseling from "./Counseling";
// import AboutCarousel from "../Components/Images/AboutCarousel";

import MLM from "../Components/Modals/MLM";
import Outlet from "../Components/Modals/Outlet";
import Engagement from "../Components/Modals/Engagement";
// import GetInvImages from "../Components/Images/GetInvImages";
import ProgramImg from "../Components/Images/ProgramImg";

const ProgramsInfo = () => {
  const handleRedirect = () => {
    let url = "https://www.aplos.com/aws/give/PeopleLikeMeProject/PLMGiving ";

    window.open(url, "_blank");
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOutletModalOpen, setIsOutletModalOpen] = useState(false);

  const [isEngageModalOpen, setIsEngageModalOpen] = useState(false);

  const openMLMModal = () => {
    setIsModalOpen(true);
  };

  const openOutletModal = () => {
    setIsOutletModalOpen(true);
  };

  const closeOutletModal = () => {
    setIsOutletModalOpen(false);
  };

  const closeEngagementModal = () => {
    setIsEngageModalOpen(false);
  };

  const openEngagementModal = () => {
    setIsEngageModalOpen(true);
  };

  return (
    <div>
      <div
        style={{
          marginTop: "4.2rem",
          backgroundColor: "#f2efef",
          padding: ".5rem",
          boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.1)",
        }}
        className="work-section-top"
      >
        <p style={{ fontSize: "32px", color: "#8983bf", marginTop: "10px" }}>
          <b>Learn About</b>
        </p>
        <h1 className="programs-subheading">What We Do</h1>
        <p className="primary-text">
          Our programs focus on central causes that we consider crucial in
          <span
            style={{
              fontFamily: "cursive",
              fontWeight: "bold",
              fontStyle: "italic",
              color: "#e6a168 ",
            }}
          >
            {" "}
            achieving
          </span>{" "}
          our goals. As a growing movement, we allocate our time and efforts to
          <span
            style={{
              fontFamily: "cursive",
              fontWeight: "bold",
              fontStyle: "italic",
              color: "#e6a168 ",
            }}
          >
            {" "}
            genuinely
          </span>{" "}
          invest in each cause.
        </p>
      </div>

      <div style={{ marginTop: "2rem" }}>
        <h3
          style={{
            fontSize: "2rem",
            marginLeft: "1.5rem",
            background: "linear-gradient(45deg, #7d74ab, #8983bf, #a395cc)",
            color: "#ececec",
          }}
          className="primary-text"
        >
          {" "}
          Our Programs
        </h3>
      </div>

      <div className="dual-programs-container">
        {/* Program 1 */}
        <div className="program-section">
          <div>
            <Diversity1 style={{ fontSize: "2rem", color: "#8983bf" }} />
            <h3 className="program-title">Men Like Me</h3>
          </div>
          <p className="program-ptext">
            We aim to empower, educate, and equip both fathers and men
            transitioning back into the community.
          </p>
          <div style={{ marginTop: "2rem" }}>
            <div style={{ textAlign: "center" }}>
              <MLM
                isModalOpen={isModalOpen}
                openMLMModal={openMLMModal}
                closeModal={() => setIsModalOpen(false)}
              />
            </div>
          </div>
        </div>

        <div className="vertical-divider"></div>

        {/* Program 2 */}
        <div className="program-section">
          <div>
            <Diversity1 style={{ fontSize: "2rem", color: "#8983bf" }} />
            <h3 className="program-title">The Outlet</h3>
          </div>
          <p className="program-ptext">
            {" "}
            Our objective is to empower and educate young individuals, enabling
            them to achieve success by providing practical tools for navigating
            life.
          </p>
          <div style={{ marginTop: "2rem" }}>
            <div style={{ textAlign: "center" }}>
              <Outlet
                isModalOpen={isOutletModalOpen}
                openOutletModal={openOutletModal}
                closeOutletModal={closeOutletModal}
              />
            </div>
          </div>
        </div>

        <div className="vertical-divider"></div>

        <div className="program-section">
          <div>
            <Diversity1 style={{ fontSize: "2rem", color: "#8983bf" }} />
            <h3 className="program-title">Community Engagement</h3>
          </div>
          <p className="program-ptext">
            A platform to support community-identified resources that will
            improve and/ or support the quality of an individual's life.
          </p>
          <div style={{ marginTop: "2rem" }}>
            <div style={{ textAlign: "center" }}>
              <Engagement
                isModalOpen={isEngageModalOpen}
                openEngagementModal={openEngagementModal}
                closeEngagementModal={closeEngagementModal}
              />
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginTop: "5rem" }}>
        <h3
          style={{
            fontSize: "2rem",
            marginLeft: "1.5rem",
            background: "linear-gradient(45deg, #7d74ab, #8983bf, #a395cc)",
            color: "#ececec",
          }}
          className="primary-text"
        >
          {" "}
          Mentoring Matters
        </h3>
      </div>
      <br />
      <div style={{ marginLeft: "2rem" }}>
        <p className="">
          Research shows that prevention makes a difference. Youth who
          participate in programing regularly are:{" "}
        </p>
        <p style={{ fontSize: "10px", textAlign: "center" }}>
          (click or hover on charts)
        </p>
        <hr
          style={{
            border: "1.5px solid #8983bf",
            width: "10%",
            margin: "0",
            marginLeft: "3rem",
          }}
        />
      </div>
      <ProgramCharts />

      <div style={{ marginTop: "5rem" }}>
        <Counseling />
      </div>
      <div>
       <ProgramImg />
        {/* <div style={{background: "linear-gradient(10deg, #7d74ab, #8983bf, #a395cc)"}}> */}
        {/* <AboutCarousel /> */}
      </div>

      {/* <Getinvolved /> */}
      <div style={{ marginTop: "1rem" }} className="center-container">
        <Button
          onClick={handleRedirect}
          variant="outlined"
          style={{
            color: "#8983bf",
            borderColor: "#8983bf",
            marginTop: "3rem",
          }}
        >
          MAKE A DONATION
        </Button>
        <ContactModal />
      </div>
      <Footer />
    </div>
  );
};

export default ProgramsInfo;
