import React from 'react';
import { Stepper, Step, StepLabel, Button} from '@mui/material';

// const PurpleStepLabel = styled(StepLabel)({
//   color: '#6a1b9a', 
// });

const CustomStepper = ({ activeStep, steps }) => {
  return (
    <Stepper activeStep={activeStep} alternativeLabel>
      {steps.map((label, index) => (
        <Step key={label}>
          <StepLabel
            StepIconProps={{
              style: { color: index === activeStep ? '#8983bf' : 'grey' },
            }}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

function getSteps() {
  return ['Reach out', 'Choose an Activity', 'Volunteer'];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return 'Visit our contact page, reach out, or give us a call. We will provide you with more information on the volunteering options!';
    case 1:
      return 'After you have all the information you want, choose an activity or event to participate in.';
    case 2:
      return 'Start volunteering and make a difference! Become a part of the People Like Me family!';
    default:
      return 'Unknown step';
  }
}

const HowToVolunteerStepper = () => {
  const steps = getSteps();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div>
      <div style={{ padding: '3rem', marginTop: '2rem' }}>
        <CustomStepper activeStep={activeStep} steps={steps} />
        <div>
          <p className='primary-text' style={{ marginBottom: '2rem' }}>{getStepContent(activeStep)}</p>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button style={{color: 'grey'}} disabled={activeStep === 0} onClick={handleBack}>
              Back
            </Button>
            <Button style={{color: '#8983bf'}} disabled={activeStep === steps.length - 1} onClick={handleNext}>
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowToVolunteerStepper;
