import React from "react";
import Footer from "../Components/Footer/Footer";
import { ArrowBack, Diversity3 } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import { Chip, List, ListItem } from "@mui/material";

const Team = () => {
  const data = [
    { name: "James Wilson", position: "Associate Director" },
    { name: "Dr. Demetra Taylor", position: "Clinical Director" },
    { name: "Derrionna Watson", position: "Executive Assistant" },
    { name: "Mike Lofreso", position: "Accounting Manager" },
    { name: "Irene Lyons", position: "Program Manager Men Like Me" },
    { name: "Trei’Von Daniels", position: "Program Manager The Outlet" },
    { name: "Rosita Torres", position: "Community Engagement Manager" },
    { name: "Stacia Clark", position: "HR and Compliance Coordinator" },
    { name: "Courtney Alexander", position: "Events Coordinator" },
    { name: "Christian Stevenson", position: "Special Projects Specialist" },
  ];

  const isMobile = window.innerWidth <= 768 && window.innerHeight <= 1024;

  return (
    <div>
      <div>
        <NavLink to='/' style={{color: 'black'}} >
        <ArrowBack style={{marginTop: '5rem', marginLeft: '1rem'}} />
        </NavLink>

        <div style={{ marginTop: "3rem" }}>
          <h3
            style={{
              fontSize: "2rem",
              marginLeft: "1.5rem",
              background: "linear-gradient(45deg, #7d74ab, #8983bf, #a395cc)",
              color: "#ececec",
            }}
            className="primary-text"
          >
            {" "}
            Administrative Staff
          </h3>
          <p className="aboutpage-ptext">
            Over 60 years of combined experience{" "}
          </p>
        </div>
        <p style={{ textAlign: "center" }}>
          {" "}
          <Diversity3 style={{ color: "#8983bf", fontSize: 65 }} />{" "}
        </p>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            flexDirection: isMobile ? 'column' : 'row'
          }}
        >
          {data.map((member, index) => (
            <div
              key={index}
              style={{
                width: isMobile ? '100%' : '48%',
                marginBottom: "20px",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <List>
                <ListItem>
                  <Chip label={member.name} />{" "}
                  <p style={{ marginLeft: ".2rem" }}> - {member.position} </p>
                </ListItem>
              </List>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Team;
