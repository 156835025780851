import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Button, InputAdornment, Alert } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SubjectIcon from "@mui/icons-material/Subject";
import emailjs from "@emailjs/browser";
import { Phone } from "@mui/icons-material";

const CustomTextField = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "", 
    subject: "",
    message: "",
  });

  const [isEmailSent, setIsEmailSent] = useState(false);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceId = "service_lbkdgkl";
    const templateId = "template_i1kaf81";
    const userKey = 'tygsa-d8aLZv-qwVw';

    emailjs.init(userKey);

    emailjs.send(serviceId, templateId, formData).then(
      (response) => {
        console.log("Email sent successfully:", response);
        setIsEmailSent(true);
        setFormData({
          name: "",
          email: "",
          phone: "",  
          subject: "",
          message: "",
        });
      },
      (error) => {
        console.error("Email sending failed:", error);
      }
    );
  };

  const inputStyles = {
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#8983bf",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#8983bf",
    },
  };

  const smallTextFieldStyles = {
    width: "100%",
  };

  const multilineStyles = {
    width: "70%",
    borderRadius: "8px",
    boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={2}>
            <TextField
              id="filled-name"
              name="from_name"
              label="Name"
              variant="standard"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircleIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ ...inputStyles, ...smallTextFieldStyles }}
              required
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              id="filled-email"
              name="user_email"
              label="Email"
              variant="standard"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutlineIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ ...inputStyles, ...smallTextFieldStyles }}
              required
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              id="filled-phone"
              name="user_phone"
              label="Phone"
              variant="standard"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Phone />
                  </InputAdornment>
                ),
              }}
              sx={{ ...inputStyles, ...smallTextFieldStyles }}
              required
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              id="filled-subject"
              name="from_subject"
              label="Subject"
              variant="standard"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SubjectIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ ...inputStyles, ...smallTextFieldStyles }}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              marginTop: "5rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              id="outlined-multiline-static"
              name="message"
              label="Type here..."
              variant="standard"
              multiline
              rows={8}
              fullWidth
              sx={{ ...inputStyles, ...multilineStyles }}
              required
              onChange={handleInputChange}
            />
          </Grid>
          <Button
            type="submit"
            variant="contained"
            style={{ backgroundColor: "#8983bf", marginTop: "1rem" }}
          >
            Send Message
          </Button>
        </Grid>
      </form>
      {isEmailSent && (
        <Alert severity="success">
          Email sent successfully!
        </Alert>
      )}
    </div>
  );
};

export default CustomTextField;
