import React from "react";
import left from "../../assets/inv1.jpeg";
import mid from "../../assets/mid.jpeg";
import right from "../../assets/right.jpeg";

const ProgramImg = () => {
  const imageStyle = {
    width: "100%",
    height: "auto",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Adjust the values as needed
    borderRadius: "8px", // Optional: Add border radius for a rounded appearance
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        padding: "12px",
      }}
    >
      <div style={{ flex: 1 }}>
        <img style={{ ...imageStyle, width: "90%" }} src={mid} alt="" />
      </div>

      <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
        <img style={imageStyle} src={left} alt="" />
      </div>

      <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
        <img style={{ ...imageStyle, width: "90%" }} src={right} alt="" />
      </div>
    </div>
  );
};

export default ProgramImg;
