import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { Campaign, LocalLibrary, VolunteerActivism } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const InvolvedCards = () => {
  const nav = useNavigate();

  const actionStepsNav = () => {
    nav("action-steps");
    window.scrollTo(0, 0);
  };

  const programNav = () => {
    nav("programs");
    window.scrollTo(0, 0);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row", // Display cards horizontally
        flexWrap: "wrap",
        marginTop: "4rem", // Allow cards to wrap to the next line
        justifyContent: "center", // Center the cards horizontally
        gap: "16px", // Adjust the gap between cards
      }}
    >
      <Card
        style={{
          width: "240px",
          borderRadius: "16px",
          marginBottom: "16px",
          backgroundColor: "#f2efef",
        }}
      >
        <CardContent>
          <IconButton color="primary" aria-label="mail">
            <LocalLibrary style={{ fontSize: 46, color: "#8983bf" }} />
          </IconButton>
          <Typography variant="h6" color="textPrimary" gutterBottom>
            LEARN
          </Typography>
          <Typography variant="body2" color="textSecondary">
            The key to solving a problem? Understand it. <br />
            Learn about the facts and the issues impacting our coummities.
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            onClick={programNav}
            size="small"
            color="primary"
            variant="contained"
            style={{ borderRadius: "20px", backgroundColor: "#8983bf" }} // Adjust the radius as needed
          >
            View our Resources
          </Button>
        </CardActions>
      </Card>

      <Card
        style={{
          width: "240px",
          borderRadius: "16px",
          marginBottom: "16px",
          backgroundColor: "#f2efef",
        }}
      >
        <CardContent>
          <IconButton color="primary" aria-label="people">
            <Campaign style={{ fontSize: 46, color: "#8983bf" }} />
          </IconButton>
          <Typography variant="h6" color="textPrimary" gutterBottom>
            ENGAGE
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Our volunteers' voices can significantly contribute to raising
            awareness on social media, and their participation has the power to
            make a meaningful impact.
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            onClick={actionStepsNav}
            size="small"
            color="primary"
            variant="contained"
            style={{ borderRadius: "20px", backgroundColor: "#8983bf" }} // Adjust the radius as needed
          >
            engage with us
          </Button>
        </CardActions>
      </Card>

      <Card
        style={{
          width: "240px",
          borderRadius: "16px",
          marginBottom: "16px",
          backgroundColor: "#f2efef",
        }}
      >
        <CardContent>
          <IconButton color="primary" aria-label="event">
            <VolunteerActivism style={{ fontSize: 46, color: "#8983bf" }} />
          </IconButton>
          <Typography variant="h6" color="textPrimary" gutterBottom>
            ACT
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Whether you have five minutes, a few hours, or a bit longer, we need
            you to stand up with us and chip in. Together, we are stronger.
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            onClick={actionStepsNav}
            size="small"
            color="primary"
            variant="contained"
            style={{ borderRadius: "20px", backgroundColor: "#8983bf" }} // Adjust the radius as needed
          >
            help those in need
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};

export default InvolvedCards;
