import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
// import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import navLogo from "../assets/navlogoo.jpeg";
import {
  Button,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slide,
} from "@mui/material";
import {
  Home as HomeIcon,
  Info as InfoIcon,
  ListAlt as ListAltIcon,
  MailOutline as MailOutlineIcon,
  Event as EventIcon,
  Moving,
  VolunteerActivism,
} from "@mui/icons-material";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { Link, NavLink } from "react-router-dom";
// import './navbar.css';

// const pages = ["Home", "About", "Programs", "Contact", "Events"];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

function NavBar() {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const pages = [
    { label: "Home", link: "/" },
    { label: "About", link: "/about-us" },
    { label: "Programs", link: "/programs" },
    { label: "Contact", link: "/contact-us" },
    { label: "Impact", link: "/impact" },
    { label: "Get Involved", link: "/action-steps" },
    { label: "Events", link: "/event-info" },
  ];

  const handleScrollToTop = () => {
    // Scroll to the top of the page
    // console.log("Scroll to Top clicked");
    window.scrollTo(0, 0);
  };


  const handleRedirect = () => {
    let url = "https://www.aplos.com/aws/give/PeopleLikeMeProject/PLMGiving ";

    window.open(url, "_blank");
  };

  function HideOnScroll(props) {
    const { children } = props;
    const trigger = useScrollTrigger();

    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    );
  }

  // const handleOpenUserMenu = (event) => {
  //   setAnchorElUser(event.currentTarget);
  // };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const toggleDrawer = () => {
    if (window.innerWidth < 960) {
      setDrawerOpen(!drawerOpen);
    }
  };

  return (
    <HideOnScroll>
      <AppBar position="fixed" sx={{ backgroundColor: "white" }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} /> */}
            <Typography
              variant="h6"
              onClick={handleScrollToTop}
              noWrap
              component={NavLink} // Use NavLink as the component
              to="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                backgroundColor: "black",
                textDecoration: "none",
              }}
            >
              <img style={{ width: "210px" }} src={navLogo} alt="" />
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleDrawer}
                color="white"
              >
                <MenuIcon style={{ fontSize: "30px" }} />
              </IconButton>
              <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer}
                sx={{
                  "@media (min-width:960px)": {
                    display: "none", // hide the Drawer on screens wider than 960 pixels
                  },
                }}
              >
                <List>
                  {pages.map((page, index) => (
                    <ListItem
                      style={{ color: "black" }}
                      component={Link} // Use Link as the component
                      to={page.link}
                      key={page.label}
                      onClick={() => {
                        toggleDrawer();
                        handleScrollToTop(); // Scroll to top when NavLink is clicked
                      }}
                    >
                      {index === 0 && (
                        <ListItemIcon>
                          <HomeIcon />
                        </ListItemIcon>
                      )}
                      {index === 1 && (
                        <ListItemIcon>
                          <InfoIcon />
                        </ListItemIcon>
                      )}
                      {index === 2 && (
                        <ListItemIcon>
                          <ListAltIcon />
                        </ListItemIcon>
                      )}
                      {index === 3 && (
                        <ListItemIcon>
                          <MailOutlineIcon />
                        </ListItemIcon>
                      )}
                      {index === 4 && (
                        <ListItemIcon>
                          <Moving />
                        </ListItemIcon>
                      )}
                      {index === 5 && (
                        <ListItemIcon>
                          <VolunteerActivism />
                        </ListItemIcon>
                      )}
                      {index === 6 && (
                        <ListItemIcon>
                          <EventIcon />
                        </ListItemIcon>
                      )}
                      <ListItemText primary={page.label} />
                    </ListItem>
                  ))}
                </List>
              </Drawer>
            </Box>
            <Typography
              variant="h5"
              noWrap
              component={NavLink} // Use NavLink as the component
              to="/"
              onClick={
                handleScrollToTop // Scroll to top when NavLink is clicked
              }
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <img
                style={{
                  width: "200px",
                  marginRight: "-.5rem",
                  display: "block",
                }}
                src={navLogo}
                alt=""
              />
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page, index) => (
                <Link
                  key={`${page.label}-${index}`}
                  to={page.link}
                  onClick={() => {
                    toggleDrawer();
                    handleScrollToTop(); // Scroll to top when NavLink is clicked
                  }}
                  style={{
                    textDecoration: "none",
                    color: "black",
                    padding: "10px 10px", // Increase padding for a more comfortable click area
                    borderRadius: "4px", // Add rounded corners for a modern look
                    transition: "background-color 0.3s, color 0.3s", // Add transition for a smooth effect
                    display: "inline-block", // Ensure inline display for consistent spacing
                  }}
                  
                >
                  <Typography variant="body1" sx={{ my: 2, display: "block" }}>
                    {page.label}
                  </Typography>
                </Link>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Get Involved">
                <Button
                  onClick={handleRedirect}
                  style={{ borderColor: "#8983bf", color: "#8983bf" }}
                  sx={{
                    borderRadius: "30px",
                    "@media (max-width: 600px)": {
                      width: "20px", // Adjust the width as needed for mobile devices
                      borderRadius: "50px", // Adjust the border radius for mobile devices
                      marginLeft: "auto",
                      // marginRight: "5px",
                      fontSize: "10px",

                      // Add some right margin for spacing
                    },
                  }}
                  variant="outlined"
                >
                  Donate
                </Button>
                {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" /> */}
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </HideOnScroll>
  );
}
export default NavBar;
