import React from "react";
// import banner from "../../assets/banner.png";
// import BannerBackground from "../Assets/home-banner-background.png";
// import BannerImage from "../Assets/home-banner-image.png";
import bannerimg from "../../assets/bannerimg.jpeg";
import "./home.css";
import { Button, Stack } from "@mui/material";
import Works from "../Works/Works";
import Accomplishments from "../Accomplishments/Accomplishments";
import Sponsors from "../Sponsor/Sponsors";
import MeetTheFounder from "../Founder/MeetTheFounder";
import Resources from "../Resources/Resources";
// import HomeContact from "../Contact/HomeContact";
import Footer from "../Footer/Footer";
import Getinvolved from "../GetInvolved/GetInvolved";
import { useNavigate } from "react-router-dom";
import GetInvImages from "../Images/GetInvImages";

const Home = () => {
  const nav = useNavigate();

  const handleRedirect = () => {
    let url = "https://www.aplos.com/aws/give/PeopleLikeMeProject/PLMGiving ";

    window.open(url, "_blank");
  };

  const honorsScrollToTop = () => {
    nav("honors-events");
    window.scrollTo(0, 0);
  };

  return (
    <div className="home-container">
      {/* <section>
        <div className="skewed"></div>
      </section> */}
      <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img
            style={{
              width: "450px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
              marginTop: "-3rem",
            }}
            src={bannerimg}
            alt=""
          />
          <div className="checkmark-item">
            <h3
              style={{
                fontSize: "26px",
                marginLeft: "1.5rem",
                background: "linear-gradient(45deg, #7d74ab, #8983bf, #a395cc)",
                color: "#ececec",
              }}
              className="primary-text"
            >
              {" "}
              Success Is Your Birthright!
            </h3>
          </div>
        </div>

        <div className="home-text-section">
          <h1 className="primary-heading">
            Building{" "}
            <span
              style={{
                fontStyle: "italic",
                fontFamily: "cursive",
                color: "#8983bf",
              }}
            >
              thriving
            </span>{" "}
            communities by{" "}
            <span
              style={{
                fontStyle: "italic",
                fontFamily: "cursive",
                color: "#8983bf",
              }}
            >
              transforming
            </span>{" "}
            lives
          </h1>
          <p className="primary-text">
            People Like Me provides evidence-based education & prevention
            services for alcohol, tobacco, & other drugs (ATOD) to individuals
            and families utilizing culturally specific and age-appropriate
            strategies for the African American community. People Like me is a behavioral health and prevention services agency.
          </p>
          <Stack spacing={1} direction="row">
            <Button
              onClick={honorsScrollToTop}
              variant="outlined"
              style={{ borderColor: "#8983bf", color: "#8983bf", }}
            >
              HONORS EVENTs
            </Button>
            <Button
              onClick={handleRedirect}
              variant="contained"
              style={{ background: "#8983bf" }}
            >
              MAKE A DONATION
            </Button>
          </Stack>
        </div>
      </div>

      <div>
        <Accomplishments />

        <Works />
        <MeetTheFounder />
        <Resources />
        <GetInvImages />
        <Getinvolved />
        <Sponsors />
        {/* <HomeContact /> */}
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Home;
