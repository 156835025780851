import React from "react";
import { Button, Divider, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Counseling = () => {
  const nav = useNavigate();

  const navToContact = () => {
    nav("contact-us");
    window.scrollTo(0, 0);
  };

  return (
    <div style={{ backgroundColor: "#f2efef",}}>
      <div style={{ marginTop: "6rem" }}>
        <h3
          style={{
            fontSize: "2rem",
            marginLeft: "1.5rem",
            background: "linear-gradient(45deg, #7d74ab, #8983bf, #a395cc)",
            color: "#ececec",
          }}
          className="primary-text"
        >
          {" "}
          Counseling
        </h3>
        <p style={{ marginLeft: "2rem" }} className="primary-text">
          PLM's licensed social workers and counselors are dedicated to
          providing the right therapy for you, including individuals and
          couples, and group counseling. Our independently licensed providers
          are exceptionally qualified to help you or a loved one create the life
          you desire. Please refer to the list of some of the services offered
          and topics discussed during treatment below.
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Stack spacing={1} direction="row">
            <Button
              onClick={navToContact}
              variant="outlined"
              style={{ color: "#8983bf", borderColor: "#8983bf" }}
            >
              Contact
            </Button>
          </Stack>
        </div>
      </div>

      <div
        style={{
          marginTop: "6rem",
        }}
      >
        <div style={{ width: "100%", padding: "0 1%" }}>
          <Divider>
            {" "}
            <h3 className="primary-text">
            <b>SERVICES</b>
              </h3>{" "}
          </Divider>
          <ul>
            <div className="primary-text" style={{ marginLeft: "2rem" }}>
              <li>Comprehensive Evaluations and Treatment</li>
              <br />
              <li>Psychotherapy & Psychoeducational</li>
              <br />
              <li>Process Groups</li>
              <br />
              <li>Recreational, art, & play therapy</li>
              <br />
              <li>Eye Movement Desensitization & Reprocessing (EMDR)</li>
            </div>
          </ul>
        </div>

        <div style={{ width: "100%", padding: "0 1%", marginTop: "6rem" }}>
        <Divider>
            {" "}
            <h3 className="primary-text">
            <b>TOPICS</b>
              </h3>{" "}
          </Divider>
          <ul>
            <div className="primary-text" style={{ marginLeft: "2rem" }}>
              <li>Academic Issues</li>
              <br />
              <li>Anger & Irritability </li>
              <br />
              <li>Depression & Anxiety</li>
              <br />
              <li>ADHD</li>
              <br />
              <li>Bipolar Disorder</li>
              <br />
              <li>Suicidal Ideations</li>
              <br />
              <li>Substance Abuse</li>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Counseling;
