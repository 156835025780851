import React from "react";
import { PieChart, Pie, Tooltip, Cell } from "recharts";

const data1 = [
  { name: "Less likely to skip a day of school", value: 52 },
  { name: "Peers", value: 48 },
];

const data2 = [
  { name: "Less likely to skip a class", value: 37 },
  { name: "Peers", value: 63 },
];

const data3 = [
  { name: "Aspire to enroll & graduate college", value: 76 },
  { name: "Others", value: 24 },
];

const data4 = [
  { name: "Less likely to use drugs", value: 46 },
  { name: "Peers", value: 54 },
];

const COLORS = ["#8983bf", "grey"];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const percentage = payload[0].value;
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          padding: "5px",
          border: "1px solid #ccc",
        }}
      >
        {`${percentage}%  ${payload[0].name.toLowerCase()}`}
      </div>
    );
  }

  return null;
};

const ProgramCharts = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "space-around",
      flexWrap: "wrap",
    }}
  >
    <PieChart width={190} height={200}>
      <Pie
        data={data1}
        dataKey="value"
        cx={100}
        cy={100}
        innerRadius={30}
        outerRadius={50}
        fill="#8884d8"
        label
      >
        {data1.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip
        contentStyle={{ fontSize: "10px" }}
        content={<CustomTooltip />}
      />
    </PieChart>
    <PieChart width={190} height={200}>
      <Pie
        data={data2}
        dataKey="value"
        cx={100}
        cy={100}
        innerRadius={30}
        outerRadius={50}
        fill="#8884d8"
        label
      >
        {data2.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip
        contentStyle={{ fontSize: "10px" }}
        content={<CustomTooltip />}
      />
    </PieChart>
    <PieChart width={190} height={200}>
      <Pie
        data={data3}
        dataKey="value"
        cx={100}
        cy={100}
        innerRadius={30}
        outerRadius={50}
        fill="#8884d8"
        label
      >
        {data3.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip
        contentStyle={{ fontSize: "10px" }}
        content={<CustomTooltip />}
      />
    </PieChart>
    <PieChart width={190} height={200}>
      <Pie
        data={data4}
        dataKey="value"
        cx={100}
        cy={100}
        innerRadius={30}
        outerRadius={50}
        fill="#8884d8"
        label
      >
        {data4.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip
        contentStyle={{ fontSize: "10px" }}
        content={<CustomTooltip />}
      />
    </PieChart>
  </div>
);

export default ProgramCharts;
