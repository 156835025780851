import React from "react";
import logo from "../../assets/PLM.png";

import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import "./footer.css";
import {
  CopyrightOutlined,
 
  LocationOn,
  Mail,
 
  Phone,
} from "@mui/icons-material";
import { IconContext } from "react-icons";

const Footer = () => {
  const linkStyle = {
    color: "#8983bf",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
  };

  const iconStyle = {
    marginRight: "10px",
    height: "20px",
    width: "20px",
    fill: "grey",
  };

  return (
    <div className="footer-wrapper">
      <div className="footer-section-one">
        <div className="footer-logo-container">
          <img src={logo} alt="" />
        </div>
        <div className="footer-icons">
          <IconContext.Provider
            value={{
              size: "2em",
              color: "#b0b0b0",
              style: { margin: "0.5rem" },
            }}
          >
            {/* Social media icons with links */}
            <a
              href="https://www.facebook.com/PLMPROJECT"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook />
            </a>
            <a
              href="https://twitter.com/plmpinc"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter />
            </a>
            <a
              href="https://instagram.com/plmpinc/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </a>
            <a
              href="https://www.linkedin.com/company/peoplelikeme/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin />
            </a>
            {/* Add more social media icons as needed */}
          </IconContext.Provider>
        </div>
      </div>
      <div className="footer-section-two">
        <div className="footer-section-columns">
          <span style={{ display: "block", marginBottom: "20px" }}>
            <a style={linkStyle} href="tel:+24453337783">
              <Phone style={iconStyle} /> 244-5333-7783
            </a>
          </span>

          <span style={{ display: "block", marginBottom: "20px" }}>
            <a style={linkStyle} href="mailto:info@peoplelikemeinc.org">
              <Mail style={iconStyle} /> info@peoplelikemeinc.org
            </a>
          </span>
          <span style={{ display: "block", marginBottom: "20px" }}>
            <a style={linkStyle} href="mailto:info@peoplelikemeinc.org">
              <LocationOn style={iconStyle} />470 W. Broad St, # 126 Columbus 43215
            </a>
          </span>

          {/* <span className="footer-link">About</span>
          <span className="footer-link">Programs</span>
          <span className="footer-link">Questions?</span>
          <span className="footer-link">Events</span> */}
        </div>
        <div className="footer-section-columns">
          <h2 style={{ color: "grey" }}>Success is Your Birthright!</h2>
        </div>
        <div className="footer-section-columns">
          <span className="footer-link">
            <p style={{ color: "#8983bf" }}>
              {" "}
              <span>
                <CopyrightOutlined style={iconStyle} />
              </span>{" "}
              2024, People Like Me, Inc
            </p>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
