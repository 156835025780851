import React from "react";
import kroger from "../../assets/krogerlogo.png";
import amazon from "../../assets/Amazon-Smile-Logo.png";
import "./sponsor.css"; // Import your CSS file

import KrogerInfo from "../Modals/sponsorsModals";

const Sponsors = () => {
  return (
    <div className="sponsors-container">
      {/* <h2
        className="center-container"
        style={{ color: "#8983bf", marginBottom: "1rem" }}
      >
        Sponsors
      </h2> */}
      <div>
        <KrogerInfo />
      </div>
      <br />

      <div className="sponsor-images">
        <div className="sponsor-image-container">
          <img style={{ width: "270px" }} src={kroger} alt="" />
          <div></div>
        </div>

        <div className="sponsor-image-container">
          <img src={amazon} alt="amazon" className="amazon-logo" />

          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Sponsors;
