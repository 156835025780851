import React from "react";
import "./involved.css";
import InvolvedCards from "./InvolvedCards";
const Getinvolved = () => {
  return (
    <div className="involved-section">
      <h1
        style={{ color: "#8983bf", textAlign: "center", paddingBlock: "1rem" }}
      >
        Get Involved Today
      </h1>
      <p style={{textAlign: 'center'}} className="primary-tex">
        We'd love to have you as a part of the People Like Me community. Check
        out the various ways to get involved below.
      </p>
      <InvolvedCards />
    </div>
  );
};

export default Getinvolved;
